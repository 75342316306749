/* 相关方列表模块 */
import { axios } from '@/utils/request';
/**
 * 相关方列表分页
 */
export function getRelatedList(parameter) {
  return axios({
    url: '/relatedParty/page',
    method: 'get',
    params: parameter
  });
}
/**
 * 相关方法等级列表或者相关方类型列表
 */
export function getRelatedTypeAndLevel(parameter) {
  return axios({
    url: '/relatedParty/getRelatedTypeAndLevel',
    method: 'get',
    params: parameter
  });
}
/**
 * 相关方新增
 */
export function addOrEditRelated(parameter) {
  return axios({
    url: '/relatedParty/addOrEditRelated',
    method: 'post',
    data: parameter
  });
}
/**
 * 相关方查看详情
 */
export function viewRelatedDetail(parameter) {
  return axios({
    url: '/relatedParty/getDetail',
    method: 'get',
    params: parameter
  });
}
/**
 * 相关方删除
 */
 export function deleteRelated(parameter) {
  return axios({
    url: '/relatedParty/deleteRelated',
    method: 'post',
    data: parameter
  });
}