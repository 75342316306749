<!--
 * @Author: your name
 * @Date: 2021-07-07 10:41:01
 * @LastEditTime: 2021-08-24 10:41:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \wenjiang\yucheng-project-web\src\views\main\_components\unfoldAndStow.vue
-->
<template>
  <div>
    <div class="bar-divider">
      <span>{{ name }}</span>
      <i
        style="font-size: 18px;line-height: 32px;"
        @click="boxshow = !boxshow"
        :class="boxshow == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
      />
    </div>
    <div :class="boxshow == true ? 'box' : 'boxHidden'">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      boxshow: true
    };
  }
};
</script>
<style lang="less" scoped>
.bar-divider {
  background: #f3f6fe;
  line-height: 32px;
  padding: 0 8px;
  border-left: 4px solid #117BF7;
  font-weight: bold;
  color: #303133;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
}
.box {
  height: 100%;
  width: 100%;
  // transition: all 0.5s ease-in-out;
}
.boxHidden {
  // transition: all 1s ease-in-out;
  height: 0;
  overflow: hidden;
}
</style>
