<template>
  <div>
    <el-upload
      v-if="!disabled"
      :headers="{ Authorization: 'Bearer ' + token, AppCode: 'whole-process' }"
      :action="uploadApiPath"
      :multiple="true"
      :show-file-list="false"
      :on-success="handleSuccess"
      :on-error="uploadError"
      :on-progress="uploadProgress"
      :disabled="fileButtonIsDisabled"
    >
      <el-button class="upload-button" v-if="defineTabel"><i class="el-icon-plus"></i>上传附件</el-button>
      <el-button v-else :disabled="fileButtonIsDisabled">上传</el-button>
    </el-upload>
    <!-- 上传成功后表格展示信息 注意:有些地方要显示档案类型这一列 -->
    <el-table
      v-if="tableFileList.length && defineTabel"
      v-loading="uploadTabelLoading"
      size="medium"
      stripe
      :max-height="300"
      :data="tableFileList"
      style="width: 100%;margin-top: 16px;"
      :row-class-name="tabelRowClassName"
    >
      <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
      <el-table-column label="文件名称" align="center" prop="fileOriginName" show-overflow-tooltip> </el-table-column>
      <el-table-column label="上传人" align="center" prop="createUserName"> </el-table-column>
      <el-table-column label="上传时间" align="center" prop="operatTime"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <div class="comm-operation">
            <a class="edit-btn mr-16" @click="previewFile(row)">查看</a>
            <a href="javascript:void(0);" class="edit-btn mr-16" @click="downLoadFile(row.fileId, row.fileOriginName)"
              >下载</a
            >
            <span class="delete-btn" slot="reference" @click="handleDeleteSingle(row)" v-if="!disabled">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { previewFile, downLoadFile } from '@/mixins/uploadFileOperation';
export default {
  name: 'UploadFile',
  mixins: [previewFile, downLoadFile],
  props: {
    // 上传文件的表格列表，不是upload中的文件列表
    tableFileList: {
      type: Array,
      default: () => []
    },
    // 是否显示上传后自定义表格(如果为true，文件上传的一切事件都是针对defineTabel为true的情况)
    defineTabel: {
      type: Boolean,
      default: true
    },
    // 是否禁用（查看详情需要传true）
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否禁用（针对于文件列表这种模块的上传禁用）
    fileButtonIsDisabled: {
      type: Boolean,
      default: false
    },
    // 文件id集合
    fileIdList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      uploadTabelLoading: false,
      uploadList: [],
      // 上传文件地址
      uploadApiPath: '/api/roses-pub-service/sysFileInfo/uploadResponse'
    };
  },
  methods: {
    // 删除表格某一项操作
    handleDeleteSingle({ index }) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message.success('删除成功');
          this.fileIdList.splice(index, 1);
          this.tableFileList.splice(index, 1);
          this.$emit('get-file-data', this.fileIdList);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 上传成功
    handleSuccess(response) {
      console.log(response, '2222222');
      // 有自定义表格的情况（其他事件也一样的意思）
      if (this.defineTabel) {
        this.uploadTabelLoading = false;
        this.tableFileList.push(response.data); // 表格显示
        this.fileIdList.push(response.data.fileId); // 获取文件id
        this.$emit('get-file-data', this.fileIdList);
      } else {
        this.$emit('get-file-data', response.data);
      }

      this.$message.success('上传成功');
    },
    // 上传失败的提示
    uploadError(err) {
      if (this.defineTabel) {
        this.uploadTabelLoading = false;
      }
      this.$message.error(err.message);
    },
    // 文件上传时的钩子
    uploadProgress() {
      if (this.defineTabel) {
        this.uploadTabelLoading = true;
      }
    },
    // 这个方法为每一行添加索引
    tabelRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      return 'rows';
    }
  },
  computed: {
    ...mapGetters(['token'])
  }
};
</script>
<style scoped>
/* 有表格的样式 */
.upload-button {
  color: #117bf7;
  display: inline-block;
  width: 180px;
  height: 40px;
}
</style>
