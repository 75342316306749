import { render, staticRenderFns } from "./interestedList.vue?vue&type=template&id=bf6eb26e&scoped=true&"
import script from "./interestedList.vue?vue&type=script&lang=js&"
export * from "./interestedList.vue?vue&type=script&lang=js&"
import style0 from "./interestedList.vue?vue&type=style&index=0&id=bf6eb26e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf6eb26e",
  null
  
)

export default component.exports