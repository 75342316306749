<template>
  <div v-loading="formLoading">
    <!-- 表单标题 -->
    <div class="top-title">
      <div class="title">{{ formTitle }}</div>
      <el-button plain size="small" @click="handleBack">返回</el-button>
    </div>
    <!-- 相关方信息 -->
    <unfoldAndStow name="相关方信息">
      <el-form class="pro-form" ref="interestedRef" size="small" :model="form" :rules="rules" label-width="200px">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="相关方名称" prop="name" :required="!disabled">
              <el-input v-model.trim="form.name" placeholder="请输入" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="typeId" label="相关方类型" :required="!disabled">
              <el-select
                :disabled="disabled"
                filterable
                v-model="form.typeId"
                placeholder="请选择（可搜索）"
                style="width: 100%;"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in relatedTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="gradeId" label="相关方等级" :required="!disabled">
              <el-select
                :disabled="disabled"
                v-model="form.gradeId"
                filterable
                placeholder="请选择（可搜索）"
                style="width: 100%;"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in relatedLevelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="项目相关方所属行业">
              <el-input
                :maxlength="100"
                v-model.trim="form.industry"
                placeholder="请输入"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="address" label="地址" :required="!disabled">
              <el-input v-model.trim="form.address" placeholder="请输入" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="contacts" label="联系人" :required="!disabled">
              <el-input v-model.trim="form.contacts" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item prop="contactInformation" label="联系方式" :required="!disabled">
              <el-input v-model.trim="form.contactInformation" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="规模">
              <el-input
                v-model.trim="form.scale"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
                :maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="负责人">
              <el-input
                :maxlength="100"
                v-model.trim="form.director"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </unfoldAndStow>
    <!-- 其他信息 -->
    <unfoldAndStow name="其他信息">
      <el-form size="small" :model="form" label-width="200px">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="开户银行">
              <el-input
                :maxlength="100"
                v-model.trim="form.bank"
                placeholder="请输入"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="银行账号">
              <el-input
                :maxlength="100"
                v-model.trim="form.bankAccount"
                placeholder="请输入"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="开户名">
              <el-input
                :maxlength="100"
                v-model.trim="form.bankAccountName"
                placeholder="请输入"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="增值税号">
              <el-input
                :maxlength="100"
                v-model.trim="form.vatNo"
                placeholder="请输入"
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="26" :lg="16" :xl="16">
            <el-form-item label="备注">
              <el-input
                :disabled="disabled"
                :placeholder="getPlaceholderTiptext"
                v-model.trim="form.remark"
                type="textarea"
                :rows="3"
                :maxlength="500"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </unfoldAndStow>
    <!-- 附件 -->
    <unfoldAndStow name="附件">
      <div class="detail-list">
        <upload-file
          :tableFileList="tableFileList"
          @get-file-data="getFileData"
          :fileIdList="fileIdList"
          :disabled="disabled"
        ></upload-file>
      </div>
    </unfoldAndStow>
    <div class="submit-btn">
      <el-button size="small" style="margin-right: 16px;" @click="handleBack">取消</el-button>
      <el-button size="small" type="primary" @click="saveInterestForm" v-if="!disabled" :loading="btnLoding"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import unfoldAndStow from 'views/main/basicPage/_components/unfoldAndStow';
import UploadFile from 'components/upload/UploadFile';
import { addOrEditRelated } from 'api/main/relatedParty';
import { getBaseList } from 'api/common/common';
export default {
  name: 'createForm',
  components: {
    unfoldAndStow,
    UploadFile
  },
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入'));
      } else if (value.length > 100) {
        return callback(new Error('长度在100个字符内'));
      } else {
        callback();
      }
    };
    var validateTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择'));
      } else {
        callback();
      }
    };
    var validateGradeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择'));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入'));
      } else if (value.length > 100) {
        return callback(new Error('长度在100个字符内'));
      } else {
        callback();
      }
    };
    var validateContacts = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入'));
      } else if (value.length > 100) {
        return callback(new Error('长度在100个字符内'));
      } else {
        callback();
      }
    };
    var validateContactInformation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入'));
      } else if (value.length > 100) {
        return callback(new Error('长度在100个字符内'));
      } else {
        callback();
      }
    };
    return {
      btnLoding: false,
      formLoading: true,
      form: {},
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        typeId: [{ validator: validateTypeId, trigger: 'change' }],
        gradeId: [{ validator: validateGradeId, trigger: 'change' }],
        address: [{ validator: validateAddress, trigger: 'blur' }],
        contacts: [{ validator: validateContacts, trigger: 'blur' }],
        contactInformation: [{ validator: validateContactInformation, trigger: 'blur' }]
      },
      //表单相关方类型下拉列表
      relatedTypeList: [],
      //表单相关方等级下拉
      relatedLevelList: [],
      //表单字段是否禁用，也可以根据这个值做其他隐藏操作
      disabled: false,
      //表单标题
      formTitle: '新增相关方',
      //回显表格列表(只是新增就不用)
      tableFileList: [],
      //回显文件id集合(只是新增就不用)
      fileIdList: []
    };
  },
  methods: {
    //初始化表单数据（这里可以对回显的一些数据做处理）
    initInterestedFormData(formObj, boolean) {
      this.formLoading = false;
      if (formObj) {//有值代表新增或者新增，否则为新增
        //表单标题
        if (boolean) {
          this.formTitle = '查看相关方';
        } else {
          this.formTitle = '修改相关方';
        }
        this.disabled = boolean;
        this.form = formObj;
        //这里处理附件回显
        if (formObj.annex.length) {
          formObj.annex.map(item => {
            //这里返回的上传时间的字段跟组件要的字段名不统一，需要处理一下
            item.operatTime = item.createTime;
            this.fileIdList.push(item.fileId);
          });
        }
        this.tableFileList = formObj.annex;
      }
    },
    // 获取相关方类型（type:4代表类型，5代表等级）
    getRelatedType() {
      getBaseList({ type: 4 }).then(res => {
        this.relatedTypeList = res.data;
      });
    },
    // 获取相关方等级（type:4代表类型，5代表等级）
    getRelatedLevel() {
      getBaseList({ type: 5 }).then(res => {
        this.relatedLevelList = res.data;
      });
    },
    //返回
    handleBack() {
      this.$emit('back', false);
    },
    //保存
    saveInterestForm() {
      this.$refs.interestedRef.validate(valid => {
        if (valid) {
          this.btnLoding = true;
          addOrEditRelated(this.form).then(res => {
            if (res.code === 200) {
              this.btnLoding = false;
              this.$message.success('操作成功');
              this.$emit('back', true);
            } else {
              this.btnLoding = false;
            }
          });
        }
      });
    },
    //上传文件返回后的文件id集合
    getFileData(fileIdsList) {
      this.form.fileIds = fileIdsList;
    }
  },
  created() {
    this.getRelatedType();
    this.getRelatedLevel();
  },
  computed: {
    //查看详情状态下的placeholder提示文字
    getPlaceholderTiptext() {
      return this.disabled ? '无' : '请输入';
    }
  }
};
</script>

<style lang="less" scoped>
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
}

.detail-list {
  margin: 16px 40px;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  padding-top: 16px;
}
</style>
