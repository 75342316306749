<!--
 * @Author: your name
 * @Date: 2021-08-16 16:46:33
 * @LastEditTime: 2021-08-19 15:43:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\basicPage\interested\interestedList.vue
-->
<template>
  <div class="interested-list">
    <div v-if="indexShow">
      <div class="title">相关方列表</div>
      <div class="query-nav">
        <el-form :model="queryForm" label-width="110px" inline>
          <el-form-item label="相关方类型">
            <el-select
              v-model="queryForm.relatedType"
              placeholder="请选择"
              @visible-change="
                boolean => {
                  changeSelectText(boolean, 4);
                }
              "
            >
              <el-option v-for="item in relatedTypeOrLevelList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="相关方等级">
            <el-select
              v-model="queryForm.relatedLevel"
              placeholder="请选择"
              @visible-change="
                boolean => {
                  changeSelectText(boolean, 5);
                }
              "
            >
              <el-option v-for="item in relatedTypeOrLevelList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input v-model.trim="queryForm.search" placeholder="相关方名称/负责人/地址"></el-input>
          </el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form>
      </div>
      <div class="buts">
        <el-button type="primary" @click="addInterestForm"><i class="el-icon-plus"></i>新建</el-button>
        <div>
          <el-button @click="handleExportTableList" :loading="expoxtLoading" :disabled="!this.ids.length">导出</el-button>
          <el-button type="danger" style="margin-left:16px" :disabled="!this.ids.length" @click="handelBatchDelete">批量删除</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          ref="tabelRef"
          size="medium"
          stripe
          v-loading="tableLoading"
          :data="interestedTabelList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60" align="center" fixed></el-table-column>
          <el-table-column width="60" fixed type="index" label="序号" align="center"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            width="200"
            fixed
            prop="name"
            label="相关方名称"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            width="200"
            prop="type"
            label="相关方类型"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            width="200"
            prop="level"
            label="相关方等级"
            align="center"
          ></el-table-column>
          <el-table-column show-overflow-tooltip width="200" prop="director" label="负责人" align="center">
            <template slot-scope="{ row }">
              {{ row.director || '-' }}
            </template></el-table-column
          >
          <el-table-column show-overflow-tooltip prop="address" label="地址" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip width="200" prop="createTime" label="创建时间" align="center">
            <template slot-scope="{ row }">
              {{ row.createTime.substr(0, 16) || '-' }}
            </template></el-table-column
          >
          <el-table-column show-overflow-tooltip prop="remark" label="备注" align="center" :resizable="false">
            <template slot-scope="{ row }">
              {{ row.remark || '-' }}
            </template></el-table-column
          >
          <el-table-column show-overflow-tooltip fixed="right" width="200" label="操作" align="center">
            <template slot-scope="{ row }">
              <div class="comm-operation">
                <span class="edit-btn mr-16" @click="viewInterestedForm(row)">查看</span>
                <span class="edit-btn mr-16" @click="UpdateInterestedForm(row)">修改</span>
                <span class="delete-btn" slot="reference" @click="handelSingleDelete(row)">删除</span>
              </div>
            </template></el-table-column
          >
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page">
        <el-pagination
          :total="total"
          :current-page="queryForm.pageNo"
          :page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 30, 50]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <create-form v-else @back="backIndex" ref="interestedRef"></create-form>
  </div>
</template>
<script>
import CreateForm from './createForm';
import { getRelatedList, getRelatedTypeAndLevel, viewRelatedDetail, deleteRelated } from 'api/main/relatedParty';
import { exportMethods } from 'utils/exportMethods';
export default {
  components: {
    CreateForm
  },
  mounted() {
    this.getRelatedTableList();
  },
  data() {
    return {
      indexShow: true,
      tableLoading: false,
      expoxtLoading: false,
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        relatedType: '',
        relatedLevel: '',
        search: ''
      },
      // 相关方类型或者等级通用
      relatedTypeOrLevelList: [],
      // 相关方列表
      interestedTabelList: [],
      ids: [] // 删除id集合
    };
  },
  methods: {
    handleExportTableList() {
      if (!this.ids.length) {
        this.$message.warning('请先勾选，再执行本操作');
      } else {
        this.exportFunc(this.ids);
      }
    },
    // 导出方法
    exportFunc(ids) {
      this.expoxtLoading = true;
      exportMethods('/relatedParty/exportRelatedPartyList', { ids }, '相关方列表')
        .then(() => {
          this.expoxtLoading = false;
          this.cancelTabelCheck();
        })
        .catch(() => {
          this.expoxtLoading = false;
        });
    },
    // 单个删除
    handelSingleDelete({ id }) {
      const ids = [];
      ids.push(id);
      this.confirmDialogFunc(ids);
    },
    handleSelectionChange(row) {
      this.ids = [];
      row.map(item => {
        this.ids.push(item.id);
      });
    },
    // 批量删除
    handelBatchDelete() {
      if (!this.ids.length) {
        this.$message.warning('请先勾选，再执行本操作');
      } else {
        this.confirmDialogFunc(this.ids);
      }
    },
    // MessageBox 弹框
    confirmDialogFunc(ids) {
      this.$confirm('是否确认删除该相关方，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteFunc(ids);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.cancelTabelCheck();
        });
    },
    // 去除tabel选中效果
    cancelTabelCheck() {
      this.$refs.tabelRef.clearSelection();
    },
    // 删除接口方法
    deleteFunc(ids) {
      deleteRelated({ ids }).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          this.cancelTabelCheck();
          this.getRelatedTableList();
        }
      });
    },
    // 修改
    UpdateInterestedForm({ id }) {
      this.viewRelatedDetailFunc(id);
    },
    // 查看
    viewInterestedForm({ id }) {
      this.viewRelatedDetailFunc(id, true);
    },
    // 查看详情方法
    viewRelatedDetailFunc(id, boolean) {
      this.indexShow = !this.indexShow;
      this.$nextTick(() => {
        viewRelatedDetail({ id })
          .then(res => {
            // 这个是调用子组件的方法进行初始化操作
            this.$refs.interestedRef.initInterestedFormData(res.data, boolean);
          })
          .catch(() => {
            this.indexShow = true;
          });
      });
    },
    changeSelectText(boolean, type) {
      // 下拉框出现调取接口
      if (boolean) {
        this.getRelatedTypeOrLevel(type);
      }
    },
    // 获取相关方类型或者等级（type:4代表类型，5代表等级）
    getRelatedTypeOrLevel(type) {
      getRelatedTypeAndLevel({ type }).then(res => {
        this.relatedTypeOrLevelList = res.data;
      });
    },
    // 相关方列表
    getRelatedTableList() {
      this.tableLoading = true;
      getRelatedList(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableLoading = false;
          const { records, total } = res.data;
          this.interestedTabelList = records;
          this.total = total;
        }
      });
    },
    // boolean为true为新增，为false返回到当前页
    backIndex(boolean) {
      this.indexShow = true;
      if (boolean) {
        this.queryForm.pageNo = 1;
        this.getRelatedTableList();
      }
    },
    addInterestForm() {
      this.indexShow = !this.indexShow;
      this.$nextTick(() => {
        this.$refs.interestedRef.initInterestedFormData();
      });
    },
    // 搜索
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getRelatedTableList();
    },
    // 重置
    handleReset() {
      this.queryForm = {
        pageNo: 1,
        pageSize: 10,
        relatedType: '',
        relatedLevel: '',
        search: ''
      };
      this.getRelatedTableList();
    },

    // 分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.getRelatedTableList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getRelatedTableList();
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-divider--horizontal {
  margin: 12px 0;
}
/deep/ .el-table .cell {
  white-space: pre;
}
.interested-list {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding: 0 16px 12px;
  color: #303133;
}
.divider {
  margin: 0 0 12px 0;
}
.table {
  margin-top: 12px;
}
.buts {
  margin-top: 24px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.ant-input {
  cursor: text;
  min-height: 30px;
  height: 100%;
  background-color: red;
}
.tree-drop {
  position: absolute;
  // top:-400px;
  height: 400px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
</style>
